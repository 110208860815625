.dropdown-menu {
  width: 200px;
  position: absolute;
  top: 80px;
  list-styles: none;
  text-align: start;
  navpro-overlay: true;
  z-index: 1000;
  list-style-type: none;
}

.dropdown-menu li {
  background: #fff;
  cursor: pointer;
  z-index: 1000;
}

.dropdown-menu li:hover {
  background: #edc71f;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: black;
  padding: 16px;
}